/* .wrapper{
  width: 100%;
  overflow: hidden;
}

.dashboard{
  width: 100%;
  height: 100vh;
  height: var(--doc-height);
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

@media(max-width: 540px){
  .wrapper{
    overflow-x: auto;
  }

  .dashboard.toggled{
    width: fit-content;
  }
} */

.embla__slide {
  flex: 0 0 100%;
  min-width: 0;
}